// :host {
//   highlights {
//     background-color: yellow;
//     padding: 5px;
//   }
// }

.excerpt {
  margin-bottom: 10px;
}
